import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing, Replay } from '@sentry/react';

import App from './App';
import '../../initialize';
import '../../styles/index.css';
import './styles/index.css';
import 'antd/dist/reset.css';

Sentry.init({
  dsn: 'https://3ed40c8835cf4656b6697ded67d108e8@sentry.shlab.tech/4',
  tracesSampleRate: 1.0,
  release: import.meta.env.VITE_APP_VERSION,
  integrations: [
    new BrowserTracing(),
    new Replay({
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
